<template lang="pug">
.partner-register-form-account-section
  partner-account-fields(
    :form="form"
    :errors="errors"
  )
</template>

<script setup>
import { reactive, ref, computed } from 'vue'
import PartnerAccountFields from '@sharedComponents/partner/common/account-fields.vue'

const props = defineProps({
  partner: { type: Object },
  form: { type: Object, required: true }
})

const errors = computed(() => props.partner.errors)
</script>
