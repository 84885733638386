import { defineComponent, onMounted, ref, reactive, computed } from 'vue'
import { useStore } from 'skid-composables'
import RegisterBanner from '../components/register/banner.vue'
import PartnerLoginButton from '@applicationComponents/common/partner-login-button.vue'
import BackgroundSlide from '@applicationComponents/common/background-slide.vue'
import Slogan from '@application/images/register-slogan.svg'
import AccountSection from '../components/partner/register_form/account-section.vue'
import Partner from '@models/partner.js'
import Form from 'odd-form_object'
import KButton from '@sharedComponents/common/k-button.vue'
import PasswordVisibilityMixin from '../components/mixins/password_visibility_mixin.js'
import PartnerAgreement from '../components/partner/partner-agreement.vue'

export default defineComponent({
  name: 'partner-register-container',

  components: {
    RegisterBanner,
    PartnerLoginButton,
    BackgroundSlide,
    AccountSection,
    KButton,
    PartnerAgreement
  },

  mixins: [PasswordVisibilityMixin],

  setup(props, { emit }) {
    const store = useStore()
    const form = reactive({
      email: null,
      name: null,
      password: null,
      password_confirmation: null,
      company_name: null,
      company_ein: null,
      brand_name: null
    })
    const loadUrl = () => {
      window.history.replaceState('', '', '/partner/sign_up')
    }

    const partner = reactive(new Partner())

    const data = reactive({
      policyAgreed: true,
      isAgreementActive: false,
      isLoading: false
    })

    const submitForm = async () => {
      data.isLoading = true

      try {
        // 註冊由 api 完成，取代原本的 form submit 註冊
        // 此方式可以方便處理前端畫面
        await store.dispatch('partners/signUp', {
          model: partner,
          form
        })

        // 完成後 執行登入動作
        await document.getElementById('partner-form').submit()
      } finally {
        data.isLoading = false
      }
    }

    function showAgreement() {
      data.isAgreementActive = true
    }

    // const preloadMainProduct = () => {
    //   this.$refs.main_product.value = [
    //     '商品名稱：',
    //     '經銷 / 原廠：',
    //     '可提供分潤比例：',
    //     '可銷售數量：',
    //     '商品介紹網址：(若有商品介紹頁面，我們會提供給網紅，讓網紅更快了解商品資訊)',
    //     '商品介紹：(資訊越充足，越能讓網紅理解商品特色，促進合作機會。您可多提供商品之主打功效說明、對消費者的優點、專利文件、品管文件、原物料成分、商品製作方式、視覺呈現效果)',
    //     '樣品數量：(若能提供試用樣品，您可視作產品市場試水溫，後續讓合作的網紅可透過直播、圖文形式展示您的商品，更能提升轉換率與銷售量)',
    //     '其他合作條件：',
    //     '可自行出貨：是 / 否'
    //   ].join('\n')
    // }

    onMounted(() => {
      loadUrl()
    })

    return {
      partner,
      data,
      form,
      Slogan,
      submitForm,
      showAgreement
    }
  }
})
